import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Block, clearBlock } from "../../data/Block";
import { AppThunk, RootState } from "../store";
import { Mode } from "../../types/Mode";
import { KniffelUtils } from "../../utils/KniffelUtils";

interface GameState {
    amount: number;
    mode: Mode;
    blocks: Block[];
    currentBlockIndex: number;
    showOptions: boolean;
    showNumPad: boolean;
}

const getInitialState: GameState = {
    mode: Mode.MENU,
    amount: 0,
    blocks: [],
    currentBlockIndex: 0,
    showOptions: false,
    showNumPad: false,
};

const gameSlice = createSlice({
    name: "gameSlice",
    initialState: getInitialState,
    reducers: {
        setAmount: (state, action: PayloadAction<number>) => {
            state.amount = action.payload;
        },
        setMode: (state, action: PayloadAction<Mode>) => {
            if (action.payload === Mode.MENU) {
                state.blocks = [];
            }
            if(action.payload === Mode.LOBBY){
                state.blocks.forEach(clearBlock);
            }
            state.showOptions = false;
            state.mode = action.payload;
        },
        setBlocks: (state, action: PayloadAction<Block[]>) => {
            state.blocks = action.payload;
        },
        setCurrentBlockIndex: (state, action: PayloadAction<number>) => {
            if (action.payload < state.blocks.length && action.payload > -1) {
                state.currentBlockIndex = action.payload;
            }
        },
        upadteCurrentBlock: (state, action: PayloadAction<Block>) => {
            state.blocks[state.currentBlockIndex] = action.payload;
        },
        setShowOptions: (state, action: PayloadAction<boolean>) => {
            state.showOptions = action.payload;
        },
        setShowNumPad: (state, action: PayloadAction<boolean>) => {
            state.showNumPad = action.payload;
        },
    },
});

// ======== REDUCER =======

export const GameReducer = gameSlice.reducer;

// ======== SELECTORS =======

export const gameSelectors = {
    selectAmount: (state: RootState): number => {
        if (state.game.amount > 0) {
            return state.game.amount;
        } else {
            return 0;
        }
    },
    selectMode: (state: RootState): Mode => {
        return state.game.mode;
    },

    selectBlocks: (state: RootState): Block[] => {
        return state.game.blocks;
    },

    selectBlock: (state: RootState): Block => {
        return state.game.blocks[state.game.currentBlockIndex];
    },

    selectShowOptions: (state: RootState): boolean => {
        return state.game.showOptions;
    },
};

// ======== THUNKS ========

const addBlockThunk = (name: string): AppThunk => (dispatch, getState) => {
    const blocks: Block[] | null = KniffelUtils.deepcopy(getState().game.blocks);
    if (blocks !== null) {
        if (!blocks.find((block) => block.name === name)) {
            const newBlock: Block = new Block();
            newBlock.name = name;
            blocks.push(newBlock);
            dispatch(gameSlice.actions.setBlocks(blocks));
        }
    }
};

const delBlockThunk = (name: string): AppThunk => (dispatch, getState) => {
    if (name !== "") {
        dispatch(gameSlice.actions.setBlocks(getState().game.blocks.filter((block) => block.name !== name)));
    }
};

const clearBlocks = (): AppThunk => (dispatch) => {
    dispatch(gameSlice.actions.setBlocks([]));
};

const nextPlayer = (): AppThunk => (dispatch, getState) => {
    let index: number = getState().game.currentBlockIndex;

    if (index < getState().game.blocks.length - 1) {
        index = index + 1;
        dispatch(gameSlice.actions.setCurrentBlockIndex(index));
        return;
    }

    if (index === getState().game.blocks.length - 1) {
        index = 0;
        dispatch(gameSlice.actions.setCurrentBlockIndex(index));
        return;
    }
};

const prefPlayer = (): AppThunk => (dispatch, getState) => {
    let index: number = getState().game.currentBlockIndex;

    if (index > 0) {
        index = index - 1;
        dispatch(gameSlice.actions.setCurrentBlockIndex(index));
        return;
    }

    if (index === 0) {
        index = getState().game.blocks.length - 1;
        dispatch(gameSlice.actions.setCurrentBlockIndex(index));
        return;
    }
};

export const GameActions = {
    setMode: gameSlice.actions.setMode,
    addBlock: addBlockThunk,
    delBlock: delBlockThunk,
    clearBlocks: clearBlocks,
    nextPlayer: nextPlayer,
    prefPlayer: prefPlayer,
    setShowOptions: gameSlice.actions.setShowOptions,
    updateCurrentBlock: gameSlice.actions.upadteCurrentBlock,
};
