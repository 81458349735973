import React, {FunctionComponent, useEffect, useState} from "react";
import {Button} from "./buttons/Button";
import {GameActions} from "../store/slice/GameSlice";
import {Mode} from "../types/Mode";
import {useDispatch} from "react-redux";

interface BestlistProps {
}

interface Result {
    id: number,
    name: string,
    points: number,
    gameId: number,
}

const KNIFFEL_GAME_ID: string = "2";
const BESTLIST_URL: string = "https://api.codingmonkey.space/bestlist/bestlist/getGameBestlist?gameId=";

export const Bestlist: FunctionComponent<BestlistProps> = () => {

    const dispatch = useDispatch();
    const [list, setList] = useState<JSX.Element[]>([]);

    const buildResultString = (result: Result, index: number): JSX.Element => {
        return (
            <div className={"resultRow"} key={index}>
                <label>{result.name}</label>
                <label>{result.points}</label>
            </div>
        );
    };


    useEffect(() => {

        const buildBestlist = (results: Result[]): JSX.Element[] => {
            return results
                .sort((a: Result, b: Result) => a.points - b.points)
                .reverse()
                .map((result, index) =>
                    buildResultString(result, index)
                );
        };

        fetch(BESTLIST_URL + KNIFFEL_GAME_ID)
            .then(response => response.json().then(res => setList(buildBestlist(res))));
    }, []);

    return (
        <div className={"resultView"}>
            <div className={"flexSpaceAround"}>
                <Button onClick={() => dispatch(GameActions.setMode(Mode.MENU))} text={"MENU"}/>
            </div>
            {list}
        </div>
    );
};
