import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "./components/Header";
import { Lobby } from "./components/Lobby";
import { Menu } from "./components/Menu";
import { Result } from "./components/Result";
import { Block } from "./data/Block";
import { GameActions, gameSelectors } from "./store/slice/GameSlice";
import { Mode } from "./types/Mode";
import "./css/Kniffel.css";
import { KniffelBlock } from "./components/kniffel/KniffelBlock";
import { Bestlist } from "./components/Bestlist";

export const VERSION: string = "2.3";

interface AppProps {}

export const App: FunctionComponent<AppProps> = () => {
  const mode: Mode = useSelector(gameSelectors.selectMode);
  const dispatch = useDispatch();
  const selectCurrentBlock: Block | undefined = useSelector(
    gameSelectors.selectBlock
  );

  const getHeaderTitle = (mode: Mode): string => {
    let title: string = "HEADER TITLE";
    if (mode === Mode.GAME) {
      if (selectCurrentBlock) {
        title = selectCurrentBlock.name;
      }
    } else {
      title = mode;
    }
    return title;
  };

  // --------------------------------- Swipe -------------------------------------

  const [touchStart, setTouchStart] = useState(0);

  useEffect(() => {
    const handleStartSwipeEvent = (touchEvent: TouchEvent) => {
      setTouchStart(touchEvent.touches[0].clientX);
    };

    const handleStopSwipeEvent = (touchEvent: TouchEvent) => {
      const touchEnd: number = touchEvent.changedTouches[0].clientX;
      const swipeDistanceInPixel: number = 100;

      if (mode === Mode.GAME) {
        if (touchStart - touchEnd > swipeDistanceInPixel) {
          // do your stuff here for left swipe
          dispatch(GameActions.nextPlayer());
        }

        if (touchStart - touchEnd < -swipeDistanceInPixel) {
          // do your stuff here for right swipe
          dispatch(GameActions.prefPlayer());
        }
      }

      setTouchStart(0);
    };

    document.addEventListener("touchstart", handleStartSwipeEvent);
    document.addEventListener("touchend", handleStopSwipeEvent);

    return () => {
      document.removeEventListener("touchstart", handleStartSwipeEvent);
      document.removeEventListener("touchend", handleStopSwipeEvent);
    };
  }, [dispatch, mode, touchStart]);

  // ----------------------------------------------------------------------

  return (
    <div>
      <Header title={getHeaderTitle(mode)} />
      {mode === Mode.MENU && <Menu />}
      {mode === Mode.LOBBY && <Lobby />}
      {mode === Mode.GAME && <KniffelBlock />}
      {mode === Mode.RESULT && <Result />}
      {mode === Mode.BEST && <Bestlist />}
    </div>
  );
};
