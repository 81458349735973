import React, {FunctionComponent} from "react";
import "./arrowbutton.css";

export type ArrowButtonProps = {
    left: boolean,
    onClick: () => void;
}

export const ArrowButton: FunctionComponent<ArrowButtonProps> = (props) => {

    const {left, onClick} = props;

    return (
        <button id="arrow-button" className="arrow-button" onClick={onClick}>
            <img className="arrow-icon" src={left ? "images/icons/circle-arrow-left-solid.svg" : "images/icons/circle-arrow-right-solid.svg"} alt="plus"/>
        </button>
    );
};
