import React, {FunctionComponent} from "react";
import {useDispatch} from "react-redux";
import {VERSION} from "../App";
import {GameActions} from "../store/slice/GameSlice";
import {Mode} from "../types/Mode";
import {Footer} from "./Footer";
import {Button} from "./buttons/Button";

interface MenuProps {
}

export const Menu: FunctionComponent<MenuProps> = () => {
    const dispatch = useDispatch();

    const newGame = () => {
        dispatch(GameActions.setMode(Mode.LOBBY));
    };

    const bestList =() => {
        dispatch(GameActions.setMode(Mode.BEST));
    };

    return (
        <div className={"menuView"}>
            <div className={"menuBody"}>
            <Button onClick={newGame} text={"New Game"}/>
            <Button onClick={bestList} text={"Best List"}/>
            </div>
            <Footer child={<label>{VERSION}</label>}/>
        </div>
    );
};
