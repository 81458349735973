import React, {FunctionComponent, useState} from "react";
import "../css/NumberInput.css";

interface NumberInputProps {
    header: string;
    info: string;
    value: number;
    callback: (n: number) => void;
    closeCallback: () => void;
}

export const NumberInput: FunctionComponent<NumberInputProps> = (props) => {
    const {header, info, value, callback, closeCallback} = props;

    const [newValue, setNewValue] = useState<string>();
    const [first, setFirst] = useState<boolean>(true);

    const enter = (num: string): void => {
        if (first) {
            setNewValue(num);
            setFirst(false);
        } else {
            setNewValue(newValue + num);
        }
    };

    const deleteLastNumber = (): void => {
        setNewValue(newValue?.slice(0, -1));
    };

    const closeInput = () => {
        closeCallback();
    };

    const submit = (): void => {
        if (Number(newValue) < 31) {
            callback(newValue ? Number(newValue) : 0);
            closeInput();
        } else {
            setFirst(true);
            setNewValue("0");
        }
    };

    return (
        <div className={"view"}>
            <div style={{height: "20vh", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                <div className={"divLabel"}>
                    <label style={{fontSize: "2em"}}>{header}</label>
                </div>
                <div className={"divLabel"}>
                    <label>{info}</label>
                </div>
                <input className={"input"} type="number" disabled={true} placeholder={value.toString()} value={newValue}/>
                <div className={"numPad"}>
                    <button id={"numButton"} onClick={() => enter("1")}>1</button>
                    <button id={"numButton"} onClick={() => enter("2")}>2</button>
                    <button id={"numButton"} onClick={() => enter("3")}>3</button>
                    <button id={"numButton"} onClick={deleteLastNumber}>{"<="}</button>
                    <button id={"numButton"} onClick={() => enter("4")}>4</button>
                    <button id={"numButton"} onClick={() => enter("5")}>5</button>
                    <button id={"numButton"} onClick={() => enter("6")}>6</button>
                    <button id={"numButton"} onClick={() => enter("0")}>0</button>
                    <button id={"numButton"} onClick={() => enter("7")}>7</button>
                    <button id={"numButton"} onClick={() => enter("8")}>8</button>
                    <button id={"numButton"} onClick={() => enter("9")}>9</button>
                    <button id={"numButton"} onClick={() => closeInput()} style={{backgroundColor: "red"}}>Cancel
                    </button>
                    <button className={"buttonSubmit"} onClick={() => submit()}
                                        style={{backgroundColor: "green"}}>OK</button>
                </div>
            </div>
        </div>
    );
};
