import { KniffelUtils } from "../utils/KniffelUtils";

export class Block {
    constructor(
        public name: string = "",
        public einer: number = -1,
        public zweier: number = -1,
        public dreier: number = -1,
        public vierer: number = -1,
        public fuenfer: number = -1,
        public sechser: number = -1,
        public dreierpasch: number = -1,
        public viererpasch: number = -1,
        public fullhouse: number = -1,
        public kleinestrasse: number = -1,
        public grossestrasse: number = -1,
        public kniffel: number = -1,
        public chance: number = -1,
    ) {
    }
}

export const clearBlock = (block: Block) => {
    block.einer = -1;
    block.zweier = -1;
    block.dreier = -1;
    block.vierer = -1;
    block.fuenfer = -1;
    block.sechser = -1;
    block.dreierpasch = -1;
    block.viererpasch = -1;
    block.fullhouse = -1;
    block.kleinestrasse = -1;
    block.grossestrasse = -1;
    block.kniffel = -1;
    block.chance = -1;
};

export const getGesamtOben = (block: Block): number => {
    const gesamtOben: number =
        notMinus(block.einer) +
        notMinus(block.zweier) +
        notMinus(block.dreier) +
        notMinus(block.vierer) +
        notMinus(block.fuenfer) +
        notMinus(block.sechser) ;
    return gesamtOben;
};

const notMinus = (num: number): number => {
    return num === -1 ? 0 : num;
};

/**
 * Gibt einen Bonus von 35 Punkten zurück wenn der Obere Teil mehr oder 63 Punkte ergibt, sonst 0.
 * @param block
 */
export const getBonus = (block: Block): number => {
    return getGesamtOben(block) > 62 ? 35 : 0;
};

export const getGesamtUnten = (block: Block): number => {
    const gesamtUnten: number =
        notMinus(block.dreierpasch) +
        notMinus(block.viererpasch) +
        notMinus(block.fullhouse) +
        notMinus(block.kleinestrasse) +
        notMinus(block.grossestrasse) +
        notMinus(block.kniffel) +
        notMinus(block.chance);
    return gesamtUnten;
};

export const getGesamt = (block: Block): number => {
    const gOben: number = getGesamtOben(block);
    const bonus: number = getBonus(block);
    const gUnten: number = getGesamtUnten(block);
    const gesamt: number = gOben + bonus + gUnten;
    return gesamt;
};

export const setBlockValue = (block: Block, type: string, value: number): Block => {
    let copyBlock: Block | null = KniffelUtils.deepcopy(block);
    if (copyBlock) {
        switch (type) {
            case "einer":
                copyBlock.einer = value;
                break;
            case "zweier":
                copyBlock.zweier = value;
                break;
            case "dreier":
                copyBlock.dreier = value;
                break;
            case "vierer":
                copyBlock.vierer = value;
                break;
            case "fuenfer":
                copyBlock.fuenfer = value;
                break;
            case "sechser":
                copyBlock.sechser = value;
                break;
            case "dreierpasch":
                copyBlock.dreierpasch = value;
                break;
            case "viererpasch":
                copyBlock.viererpasch = value;
                break;
            case "fullhouse":
                copyBlock.fullhouse = value;
                break;
            case "kleinestrasse":
                copyBlock.kleinestrasse = value;
                break;
            case "grossestrasse":
                copyBlock.grossestrasse = value;
                break;
            case "kniffel":
                copyBlock.kniffel = value;
                break;
            case "chance":
                copyBlock.chance = value;
                break;
        }
    } else {
        copyBlock = new Block();
    }
    return copyBlock;
};
