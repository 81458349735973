export const KniffelUtils = {
    deepcopy<T>(object: T): T | null {
        if (object !== null || object !== undefined) {
            return JSON.parse(JSON.stringify(object));
        } else {
            console.warn("Cant make deep copy! Object is null or undefind!");
            return null;
        }
    },
};
