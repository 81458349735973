import React, {FunctionComponent, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {GameActions, gameSelectors} from "../store/slice/GameSlice";
import {Block, getGesamt} from "../data/Block";
import {Mode} from "../types/Mode";
import {KniffelUtils} from "../utils/KniffelUtils";
import {Button} from "./buttons/Button";

interface ResultProps {
}

const BESTLIST_URL: string = "https://api.codingmonkey.space/bestlist/bestlist/addRecord";

export const Result: FunctionComponent<ResultProps> = () => {
    const blocks: Block[] = useSelector(gameSelectors.selectBlocks);
    const dispatch = useDispatch();

    useEffect(() => {
        blocks.forEach((block) => {
            const points: number = getGesamt(block);

            const requestOptions = {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    id: -1,
                    name: block.name,
                    points: points,
                    gameId: 2,
                }),
            };

            fetch(BESTLIST_URL, requestOptions).catch((error) => console.warn(error));
        });
    }, [blocks]);

    const getSortedBlocks = (blocks: Block[]): Block[] => {
        const copyBlocks: Block[] | null = KniffelUtils.deepcopy(blocks);

        if (copyBlocks) {
            copyBlocks
                .sort((a: Block, b: Block) => getGesamt(a) - getGesamt(b))
                .reverse();
        }
        return copyBlocks || [];
    };

    const buildResult = (block: Block): JSX.Element => {
        return (
            <div
                className={"resultRow"}
                onClick={() => dispatch(GameActions.setMode(Mode.GAME))}
                key={block.name}
            >
                <label>{block.name}</label>
                <label>{getGesamt(block)}</label>
            </div>
        );
    };

//   <FontAwesomeIcon
//           size="1x"
//           style={{
//             width: "5em",
//             color: getBonus(block) === 0 ? "red" : "green",
//           }}
//           icon={getBonus(block) === 0 ? faTimesCircle : faCheckCircle}
//         />


    return (
        <div className={"resultView"}>
            <div className={"flexSpaceAround"}>
                <Button
                    onClick={() => dispatch(GameActions.setMode(Mode.MENU))}
                    text={"MENU"}
                />
                <Button
                    onClick={() => dispatch(GameActions.setMode(Mode.LOBBY))}
                    color={"red"}
                    text={"REMATCH"}
                />
            </div>
            {getSortedBlocks(blocks).map(buildResult)}
        </div>
    );
};
