import React, {FunctionComponent} from "react";

interface ButtonProps {
    onClick: () => void;
    color?: string
    text?: string
    disabled?: boolean
}

export const Button: FunctionComponent<ButtonProps> = (props) => {
    const {onClick, color, text, disabled} = props;

    return (
        <button onClick={onClick} style={{backgroundColor: color}} disabled={disabled}>{text}</button>
    );
};
