import React, { FunctionComponent } from "react";

interface FooterProps {
    child: JSX.Element;
}

export const Footer: FunctionComponent<FooterProps> = (props) => {
    const { child } = props;

    return <div className={"footer"}>{child}</div>;
};
