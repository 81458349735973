import React, {FunctionComponent} from "react";
import {useDispatch, useSelector} from "react-redux";
import "../css/Colors.css";
import {Block} from "../data/Block";
import {GameActions, gameSelectors} from "../store/slice/GameSlice";
import {Mode} from "../types/Mode";
import {ArrowButton} from './buttons/ArrowButton';

interface HeaderProps {
    title: string;
}

export const Header: FunctionComponent<HeaderProps> = (props) => {
    const {title} = props;
    const mode: Mode = useSelector(gameSelectors.selectMode);
    const blocks: Block[] = useSelector(gameSelectors.selectBlocks);
    const showOptions: boolean = useSelector(gameSelectors.selectShowOptions);

    const showArrows = (): boolean => {
        return blocks.length > 1;
    };

    const dispatch = useDispatch();

    return (
        <div
            className={"header"}
            style={{
                justifyContent:
                    mode === Mode.GAME && showArrows() ? "space-between" : "center",
            }}
        >
            {mode === Mode.GAME && showArrows() && (
                <ArrowButton left={true} onClick={() => dispatch(GameActions.prefPlayer())}/>
            )}
            <h2 onClick={() => dispatch(GameActions.setShowOptions(!showOptions))}>
                {title}
            </h2>
            {mode === Mode.GAME && showArrows() && (
                <ArrowButton left={false} onClick={() => dispatch(GameActions.nextPlayer())}/>
            )}
        </div>
    );
};
