import React, {FunctionComponent, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Block, getBonus} from "../../data/Block";
import {GameActions, gameSelectors} from "../../store/slice/GameSlice";
import {KniffelUtils} from "../../utils/KniffelUtils";
import {NumberInput} from "../NumberInput";
import {Options} from "../Options";
import {KniffelRow} from "./KniffelRow";
import {KniffelImage} from "./KniffelImage";

interface KniffelBlockProps {
}

export const KniffelBlock: FunctionComponent<KniffelBlockProps> = () => {
    const block: Block = useSelector(gameSelectors.selectBlock);
    const showOptions: boolean = useSelector(gameSelectors.selectShowOptions);
    const [showNumPad, setShowNumPad] = useState<JSX.Element | null>(null);
    const [key, setKey] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        setKey((prevState) => prevState + 1);
    }, [block.name]);

    const updateOberenBlockButton = (type: string, value: number) => {
        const copyBlock: Block | null = KniffelUtils.deepcopy(block);
        if (copyBlock) {
            switch (type) {
                case "einer":
                    copyBlock.einer = updateBlockNumber(1, copyBlock.einer);
                    break;
                case "zweier":
                    copyBlock.zweier = updateBlockNumber(2, copyBlock.zweier);
                    break;
                case "dreier":
                    copyBlock.dreier = updateBlockNumber(3, copyBlock.dreier);
                    break;
                case "vierer":
                    copyBlock.vierer = updateBlockNumber(4, copyBlock.vierer);
                    break;
                case "fuenfer":
                    copyBlock.fuenfer = updateBlockNumber(5, copyBlock.fuenfer);
                    break;
                case "sechser":
                    copyBlock.sechser = updateBlockNumber(6, copyBlock.sechser);
                    break;
            }
            dispatch(GameActions.updateCurrentBlock(copyBlock));
        }
    };

    const updateUnterenBlockButton = (type: string, value: number) => {
        const copyBlock: Block | null = KniffelUtils.deepcopy(block);
        if (copyBlock) {
            switch (type) {
                case "fullhouse":
                    copyBlock.fullhouse = updateBlockNumberUnten(25, copyBlock.fullhouse);
                    break;
                case "kleinestrasse":
                    copyBlock.kleinestrasse = updateBlockNumberUnten(
                        30,
                        copyBlock.kleinestrasse
                    );
                    break;
                case "grossestrasse":
                    copyBlock.grossestrasse = updateBlockNumberUnten(
                        40,
                        copyBlock.grossestrasse
                    );
                    break;
                case "kniffel":
                    copyBlock.kniffel = updateBlockNumberUnten(50, copyBlock.kniffel);
                    break;
                case "dreierpasch":
                    copyBlock.dreierpasch = value;
                    break;
                case "viererpasch":
                    copyBlock.viererpasch = value;
                    break;
                case "chance":
                    copyBlock.chance = value;
                    break;
            }
            dispatch(GameActions.updateCurrentBlock(copyBlock));
        }
    };

    const updateBlockNumberUnten = (
        baseValue: number,
        currentValue: number
    ): number => {
        let newNumber: number = -1;
        switch (currentValue) {
            case -1:
                newNumber = baseValue;
                break;
            case baseValue:
                newNumber = 0;
                break;
            case 0:
                newNumber = -1;
                break;
        }
        return newNumber;
    };

    const updateBlockNumber = (
        baseValue: number,
        currentValue: number
    ): number => {
        let newNumber: number = -1;
        switch (currentValue) {
            case -1:
                newNumber = baseValue;
                break;
            case baseValue * 5:
                newNumber = 0;
                break;
            case 0:
                newNumber = -1;
                break;
            default:
                newNumber = currentValue + baseValue;
        }
        return newNumber;
    };

    const getGameblock = (): JSX.Element => {
        return (
            <div className={"kniffelBlock"} key={key}>
                <div id="top">
                    <KniffelRow
                        imageSrc="images/einer.jpg"
                        buttonLabel={block.einer.toString()}
                        onClick={() => {
                            updateOberenBlockButton("einer", block.einer);
                        }}
                    />
                    <KniffelRow
                        imageSrc="images/zweier.jpg"
                        buttonLabel={block.zweier.toString()}
                        onClick={() => {
                            updateOberenBlockButton("zweier", block.zweier);
                        }}
                    />
                    <KniffelRow
                        imageSrc="images/dreier.jpg"
                        buttonLabel={block.dreier.toString()}
                        onClick={() => {
                            updateOberenBlockButton("dreier", block.dreier);
                        }}
                    />
                    <KniffelRow
                        imageSrc="images/vierer.jpg"
                        buttonLabel={block.vierer.toString()}
                        onClick={() => {
                            updateOberenBlockButton("vierer", block.vierer);
                        }}
                    />
                    <KniffelRow
                        imageSrc="images/fuenfer.jpg"
                        buttonLabel={block.fuenfer.toString()}
                        onClick={() => {
                            updateOberenBlockButton("fuenfer", block.fuenfer);
                        }}
                    />
                    <KniffelRow
                        imageSrc="images/sechser.jpg"
                        buttonLabel={block.sechser.toString()}
                        onClick={() => {
                            updateOberenBlockButton("sechser", block.sechser);
                        }}
                    />
                    <div className={"gameRow"}>
                        <KniffelImage src={"images/bonus.jpg"}/>
                        <div className="image-wrapper">
                            <img
                                id="bonus-check-icon"
                                src={
                                    getBonus(block) > 1
                                        ? "images/icons/circle-check-solid.svg"
                                        : "images/icons/circle-xmark-solid.svg"
                                }
                                alt="check"
                            />
                        </div>
                    </div>
                </div>

                <div id="bottom">
                    <KniffelRow
                        imageSrc="images/3pasch.jpg"
                        buttonLabel={block.dreierpasch.toString()}
                        onClick={() => {
                            setShowNumPad(
                                <NumberInput
                                    header={"3er Pasch"}
                                    info={"Alle Augen zählen"}
                                    value={block.chance}
                                    callback={(n: number) =>
                                        updateUnterenBlockButton("dreierpasch", n)
                                    }
                                    closeCallback={() => setShowNumPad(null)}
                                />
                            );
                        }}
                    />
                    <KniffelRow
                        imageSrc="images/4pasch.jpg"
                        buttonLabel={block.viererpasch.toString()}
                        onClick={() => {
                            setShowNumPad(
                                <NumberInput
                                    header={"4er Pasch"}
                                    info={"Alle Augen zählen"}
                                    value={block.chance}
                                    callback={(n: number) =>
                                        updateUnterenBlockButton("viererpasch", n)
                                    }
                                    closeCallback={() => setShowNumPad(null)}
                                />
                            );
                        }}
                    />
                    <KniffelRow
                        imageSrc="images/fullhouse.jpg"
                        buttonLabel={block.fullhouse.toString()}
                        onClick={() => {
                            updateUnterenBlockButton("fullhouse", block.fullhouse);
                        }}
                    />
                    <KniffelRow
                        imageSrc="images/kleinestrasse.jpg"
                        buttonLabel={block.kleinestrasse.toString()}
                        onClick={() => {
                            updateUnterenBlockButton("kleinestrasse", block.kleinestrasse);
                        }}
                    />
                    <KniffelRow
                        imageSrc="images/grossestrasse.jpg"
                        buttonLabel={block.grossestrasse.toString()}
                        onClick={() => {
                            updateUnterenBlockButton("grossestrasse", block.grossestrasse);
                        }}
                    />
                    <KniffelRow
                        imageSrc="images/kniffel.gif"
                        buttonLabel={block.kniffel.toString()}
                        onClick={() => {
                            updateUnterenBlockButton("kniffel", block.kniffel);
                        }}
                        icon={
                            block.kniffel === 50
                                // ? faGrinBeam
                                ? "images/icons/face-grin-beam-solid.svg"
                                : block.kniffel === 0
                                    // ? faMehRollingEyes
                                    ? "images/icons/face-rolling-eyes-solid.svg"
                                    // : faMehBlank
                                    : "images/icons/face-meh-blank-solid.svg"
                        }
                    />
                    <KniffelRow
                        imageSrc="images/chance.jpg"
                        buttonLabel={block.chance.toString()}
                        onClick={() => {
                            setShowNumPad(
                                <NumberInput
                                    header={"Chance"}
                                    info={"Alle Augen zählen"}
                                    value={block.chance}
                                    callback={(n: number) =>
                                        updateUnterenBlockButton("chance", n)
                                    }
                                    closeCallback={() => setShowNumPad(null)}
                                />
                            );
                        }}
                    />
                </div>
            </div>
        );
    };

    const getView = (): JSX.Element => {
        if (showNumPad) {
            return showNumPad;
        }
        if (showOptions) {
            return <Options/>;
        }
        return getGameblock();
    };

    return getView();
};