import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { Mode } from "../types/Mode";
import { GameActions } from "../store/slice/GameSlice";
import { Button } from "./buttons/Button";

interface OptionsProps {}

export const Options: FunctionComponent<OptionsProps> = () => {
  const dispatch = useDispatch();

  return (
      <div className={"menuView"}>
          <div className="menuBody">
        <Button
          onClick={() => dispatch(GameActions.setMode(Mode.RESULT))}
          color={"green"}
          text={"FINISH"}
        />
        <Button
          onClick={() => dispatch(GameActions.setMode(Mode.MENU))}
          color={"red"}
          text={"Quit"}
        />
        <Button
          onClick={() => dispatch(GameActions.setShowOptions(false))}
          color={"var(--color-background-card)"}
          text={"Back"}
        />
      </div>
    </div>
  );
};
