import React, {FunctionComponent, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Block} from "../data/Block";
import {GameActions, gameSelectors} from "../store/slice/GameSlice";
import {Mode} from "../types/Mode";
import {AddButton} from "./buttons/AddButton";
import {Button} from "./buttons/Button";
import type {} from 'redux-thunk/extend-redux';

interface LobbyProps {
}

export const Lobby: FunctionComponent<LobbyProps> = () => {
    const dispatch = useDispatch();

    const blocks: Block[] = useSelector(gameSelectors.selectBlocks);

    const cancel = () => {
        dispatch(GameActions.clearBlocks());
        dispatch(GameActions.setMode(Mode.MENU));
    };

    const [text, setText] = useState<string>("");

    useEffect(() => {
        function handleUserKeyPress(event: KeyboardEvent) {
            if (event.key === "Enter") {
                if (text !== "") {
                    dispatch(GameActions.addBlock(text));
                    setText("");
                }
            }
        }

        window.addEventListener("keydown", handleUserKeyPress);

        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    });

    const buildPlayers = (): JSX.Element[] => {
        return blocks.map((block) => {
            return (
                <div className={"lobbyListItem"} key={block.name}
                     onClick={() => dispatch(GameActions.delBlock(block.name))}>
                    {block.name}
                </div>
            );
        });
    };

    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (inputRef !== null) {
            if (inputRef.current !== null) {
                inputRef.current.focus();
            }
        }
    }, []);

    const startGame = () => {
        dispatch(GameActions.setMode(Mode.GAME));
    };

    return (
        <div className={"lobbyView"}>
            <div className={"buttonGroup"}>
                <Button
                    color={blocks.length <= 0 ? "var(--color-border)" : "var(--color-primary)"}
                    disabled={blocks.length <= 0}
                    onClick={() => startGame()}
                    text={"START GAME"}
                />
                <Button
                    color="var(--color-warning)"
                    onClick={() => cancel()}
                    text={"CANCEL"}
                />
            </div>

            <div className={"flexSpaceAround"}>

                <input
                    className={"lobbyInput"}
                    ref={inputRef}
                    placeholder="Player name"
                    type="text"
                    value={text}
                    onChange={(event) => setText(event.target.value)}
                />
                <AddButton onClick={() => {
                    dispatch(GameActions.addBlock(text));
                    setText("");
                }}/>
            </div>

            <div className={"lobbyBlock"}>{buildPlayers()}</div>
        </div>
    );
};
