import React, { FunctionComponent } from "react";

interface KniffelButtonProps {
    onClick: () => void;
    buttonLabel: string;
}

export const KniffelButton: FunctionComponent<KniffelButtonProps> = (props) => {
    const { onClick, buttonLabel } = props;

    const getButtonColor = (): string => {
        switch (buttonLabel) {
            case "-1":
                return "#2b9eb3";
            case "0":
                return "#f8333c";
        }
        return "#44af69";
    };

    const getButtonLabel = (): string => {
        switch (buttonLabel) {
            case "-1":
                return "???";
            case "0":
                return "---";
        }
        return buttonLabel;
    };

    return (
        <button className={"kniffelButton"} onClick={onClick} style={{backgroundColor: getButtonColor()}}>{getButtonLabel()}</button>
    );
};
