import React, {FunctionComponent} from "react";
import "./addbutton.css";

interface AddButtonProps {
    onClick: () => void;
}

export const AddButton: FunctionComponent<AddButtonProps> = (props) => {
    const {onClick} = props;

    return (
        <button id="addButton" className="add-button" onClick={onClick}>
            <img className="add-icon" src="images/icons/plus-solid.svg" alt="plus"/>
        </button>
    );
};