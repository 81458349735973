import React, { FunctionComponent } from "react";
import {KniffelImage} from "./KniffelImage";
import {KniffelButton} from "./KniffelButton";

interface KniffelRowProps {
    imageSrc: string;
    buttonLabel: string;
    onClick: () => void;
    icon?: string;
}

export const KniffelRow: FunctionComponent<KniffelRowProps> = (props) => {
    const { imageSrc, buttonLabel, onClick, icon } = props;

    return (
        <div className={"gameRow"}>
            {!icon && <KniffelImage src={imageSrc} />}
            {icon && <KniffelImage src={icon}/>}
            {/* {icon && <FontAwesomeIcon icon={icon} size="2x" style={{ width: "5em" }} />} */}
            <KniffelButton onClick={onClick} buttonLabel={buttonLabel}/>
        </div>
    );
};
