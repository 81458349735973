import { configureStore, ThunkAction } from "@reduxjs/toolkit";
import { GameReducer } from "./slice/GameSlice";
// ...
export const store = configureStore({
    reducer: {
        game: GameReducer,
    },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, unknown, any>;
