import React, { FunctionComponent } from "react";

interface KniffelImageProps {
  src: string;
}

export const KniffelImage: FunctionComponent<KniffelImageProps> = (props) => {
  const { src } = props;

  return (
    <div className="image-wrapper">
      <img className={"kniffelImage"} src={src} alt="alt" />
    </div>
  );
};
